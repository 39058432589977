<template>
  <div>
    <div class="supplier-menu">
      <div class="supplier-menu-main">
        <div class="content">
          <a
            :href="item.url"
            v-for="item in menuList"
            :key="item.id"
            :class="['item', item.id == active ? 'on' : '']"
          >
            {{ item.name }} 
          </a>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    active: {
      default: 1,
      type: Number,
    },
    banner: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menuList: [
        // { id: 1, name: "在线制造平台", url: "/make" },
        { id: 1, name: "资源供应商", url: "/company/list?cid=1" },
        { id: 2, name: "企业服务商", url: "/company/list?cid=2" },
        { id: 3, name: "商品提供商", url: "/company/list?cid=3" },
        { id: 4, name: "其他服务商", url: "/company/list?cid=4" },
      ],
    };
  },
};
</script>